import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import Typical from "react-typical";
import Seo from "../../components/seo";
import Navbar from "../../components/navbar";
import { title } from "process";
// import styles from './Projects.module.scss';

const SubtitleAnimation = React.memo(
  () => {
    const steps = ["...sometimes unfinished"];
    return <Typical steps={steps} wrapper="span" />;
  },
  (props, prevProp) => true
);

const Projects = ({ data }) => {
  return (
    <>
      <Seo
        title="Projects"
        description="Various projects and tinkerings"
        vercel={{ title: "My projects…check them out!" }}
      />
      <article>
        <header>
          <Navbar backNavbar={true} />
          <h1>Projects</h1>
          <p className="subtitle">
            <SubtitleAnimation />
          </p>
        </header>
        <blockquote>
          <p>
            A man who becomes conscious of the responsibility he bears toward a
            human being who affectionately waits for him, or to an unfinished
            work, will never be able to throw away his life. He knows the ‘why’
            for his existence, and will be able to bear almost any ‘how.’
          </p>
          <footer>
            Viktor E. Frankl, <cite>“Man's Search for Meaning”</cite>
          </footer>
        </blockquote>
        <section>
          {data.allMdx.nodes.map((project) => (
            <section key={project.id}>
              <h2>
                {project.frontmatter.publish ? (
                  <Link to={`${project.slug}`}>
                    {project.frontmatter.title}
                  </Link>
                ) : (
                  <>{project.frontmatter.title}</>
                )}
              </h2>
              <small>
                {project.frontmatter.date}
                {"—"}
                {project.fields.readingTime.text}
              </small>
              {project.frontmatter.publish ? (
                <p>
                  {project.frontmatter.abstract}{" "}
                  <small>
                    <Link to={`${project.slug}`}>Read more</Link> ☛
                  </small>
                </p>
              ) : (
                <p>
                  Project write-up coming soon…
                  {project.frontmatter.github_url ? (
                    <span>
                      see{" "}
                      <a
                        href={project.frontmatter.github_url}
                        title={project.frontmatter.title}
                      >
                        code
                      </a>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              )}
            </section>
          ))}
        </section>
      </article>
    </>
  );
};

export const pageQuery = graphql`
  query projectsQuery {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { category: { eq: "project" } } }
    ) {
      nodes {
        frontmatter {
          title
          github_url
          project_url
          abstract
          date(formatString: "MMMM D, YYYY")
          publish
          category
        }
        id
        slug
        fields {
          readingTime {
            text
          }
        }
      }
    }
  }
`;

export default Projects;
